import React, { useEffect, useRef } from 'react';
import Select, { StylesConfig } from 'react-select';
import clsx from 'clsx';

import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { Option } from '@shared/interfaces/option.interface';
import { t } from 'i18next';

interface PrimaryDropdownProps {
  className?: string;
  options: any;
  label?: string;
  placeholder?: string;
  helperText?: string | React.ReactElement;
  errorText?: string | undefined;
  value: null | object | string | number;
  onChange: any;
  onBlur?: any;
  onInput?: (value: string) => void;
  searchable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  inputValue?: string;
}

const stylesPrimaryDropdown: StylesConfig<Option, false> = {
  control: () => {
    return {
      display: 'flex',
      width: '100%',
      height: '100%',
    };
  },

  indicatorSeparator: () => ({
    display: 'none',
  }),

  // @ts-ignore
  dropdownIndicator: (_, state) => {
    return {
      display: 'flex',
      paddingRight: 10,
      paddingLeft: 10,
      color: '#5f5f5f',
      transition: 'all 0.3s ease',
      transform: state.selectProps.menuIsOpen && 'rotate(-180deg)',
    };
  },

  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,

      margin: '0',
      padding: '13px 16px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.5)',

      ...(state.hasValue
        ? {
            padding: '18px 16px 8px',
          }
        : {}),
    };
  },

  singleValue: (provided, state) => {
    return {
      ...provided,
      // It's needed to hide current input value on "focus" for searchable selects
      display: state.selectProps.isSearchable && state.selectProps.menuIsOpen ? 'none' : 'block',
      margin: '0',
    };
  },

  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: '0',
      padding: '0',
      transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
      color: 'rgba(0, 0, 0, 0.5)',

      ...(state.hasValue || state.isFocused
        ? {
            transform: 'translateY(-19px)',
            fontSize: '13px',
            lineHeight: '18px',
          }
        : {}),
    };
  },

  menu: (provided: any) => {
    return {
      ...provided,
      padding: '16px 0',
      boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.2)',
      borderRadius: '0',
    };
  },

  option: (provided: any, state: any) => {
    return {
      ...provided,
      padding: '5px 16px',
      color: '#000',
      fontSize: '16px',
      lineHeight: '24px',
      backgroundColor: 'none',
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
      ':active': {
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
      },

      ...(state.isFocused
        ? {
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
          }
        : {}),
    };
  },
};

const { ValueContainer, Placeholder, NoOptionsMessage } = components;

const SelectContainer = ({ children, ...props }: any) => {
  const { t } = useTranslation();

  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused || props.selectProps.inputValue}>
        {t(props.selectProps.placeholder)}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
};

const NoOptionsMessageCustom = (props: any) => {
  const { inputValue, isSearchable } = props.selectProps;
  const message = isSearchable ? (inputValue === '' ? 'start-input' : 'enter-something-else') : 'no-options';

  return <NoOptionsMessage {...props}>{t(message)}</NoOptionsMessage>;
};

export const PrimaryDropdown: React.FC<PrimaryDropdownProps> = (props) => {
  const ref = useRef<any>();
  const {
    options = [],
    className,
    label,
    placeholder = label,
    helperText = '',
    errorText = undefined,
    value = '',
    onChange,
    onBlur = () => {},
    onInput,
    disabled = false,
    isLoading = false,
    searchable = false,
    inputValue,
  } = props;

  useEffect(() => {
    if (!ref.current) return;
    if (value) return;

    ref.current.clearValue();
  }, [value]);

  return (
    <div
      className={clsx(
        'relative h-[50px] border-b-[1px] border-b-solid',
        'hover:bg-black-7 focus-within:bg-black-7',
        errorText ? 'border-b-carnation' : 'border-b-black',
        disabled && 'bg-black-7',
        className,
      )}
    >
      <Select
        ref={ref}
        value={options.find((c: Option) => c.value === value)}
        onChange={(val) => onChange(val ? val.value : '')}
        onBlur={onBlur}
        inputValue={inputValue}
        onInputChange={onInput}
        className="w-full h-full"
        options={options}
        isSearchable={searchable}
        styles={stylesPrimaryDropdown}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={disabled}
        components={{
          ValueContainer: SelectContainer,
          NoOptionsMessage: NoOptionsMessageCustom,
        }}
      />
      {(errorText || helperText) && (
        <span
          className={clsx(
            'absolute left-0 top-full mt-[5px] px-4 text-xxs',
            errorText && 'text-carnation',
            !errorText && helperText && 'text-black-50',
          )}
        >
          {errorText || helperText}
        </span>
      )}
    </div>
  );
};
